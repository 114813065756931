<div mat-dialog-title class="flex flex-row">
    Vidal Sécurisation
    <div class="flex flex-1"></div>
    <button mat-button color="primary" (click)="launchDrugsInteractions()">
        <mat-icon>medication</mat-icon>
        <span>Interactions médicamenteuses</span>
    </button>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div
    *transloco="let t; read: scope"
    mat-dialog-content
    class="flex flex-col h-full items-center justify-center gap-x-4 gap-y-4"
>
    <fuse-alert type="info">{{ t('SECURISATION.INFO_MESSAGE') }}</fuse-alert>

    <fuse-alert *ngIf="showAlert" [type]="'error'" [showIcon]="false" [appearance]="'outline'">
        {{ t('SECURISATION.ERROR_MESSAGE') }}
    </fuse-alert>
    <iframe #divContent id="divContent" class="flex flex-1 h-full w-full"></iframe>
</div>
