import { PriscaRequest } from '@services/vidal/entities/prisca-request';
import { DrugPrescriptionLine } from '@services/vidal/entities/drug-prescription';
import { VidalPriscaError } from '@services/vidal/enum/vidal-prisca-error';
import { Patient } from '@mapuilabs/hv-interfaces';

export class PriscaRequestBuilder {
    private patient?: Patient;
    private drugPrescriptions: DrugPrescriptionLine[] = [];

    public setPatient(patient: Patient): PriscaRequestBuilder {
        this.patient = patient;
        return this;
    }

    public addPrescription(drug: DrugPrescriptionLine): PriscaRequestBuilder {
        this.drugPrescriptions.push(drug);
        return this;
    }

    public addPrescriptions(drugs: DrugPrescriptionLine[]): PriscaRequestBuilder {
        this.drugPrescriptions = this.drugPrescriptions.concat(drugs);
        return this;
    }

    public build(): PriscaRequest {
        if (this.patient == undefined) {
            throw VidalPriscaError.MISSING_PATIENT;
        }
        if (this.drugPrescriptions.length === 0) {
            throw VidalPriscaError.MISSING_DRUGS;
        }
        return new PriscaRequest(this.patient, this.drugPrescriptions);
    }
}
