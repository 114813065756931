import { DrugPrescriptionLine } from '@services/vidal/entities/drug-prescription';
import { DrugPrescriptionDurationType } from '@services/vidal/enum/drug-prescription-duration-type';
import { DrugPrescriptionFrequencyType } from '@services/vidal/enum/drug-prescription-frequency-type';
import { VidalPriscaError } from '@services/vidal/enum/vidal-prisca-error';
import { VidalMapper } from '@services/vidal/vidal.mapper';
import { EDosageEquivalent, EDurationUnit } from '@shared/select-posology/posology.const';
import { Dosage } from '@services/vidal/entities/dosage';

export class DrugPrescriptionBuilder {
    private vidalUri?: string = undefined;
    private unitId?: number = undefined;
    private dose?: number = undefined;
    private duration?: number = undefined;
    private durationType?: DrugPrescriptionDurationType = undefined;
    private frequencyType?: DrugPrescriptionFrequencyType = undefined;
    private dosages: Dosage[] = [];
    private prescriptionLines: DrugPrescriptionLine[] = [];

    constructor(private vidalMapper: VidalMapper) {}

    public setVidalUri(vidalId: string): DrugPrescriptionBuilder {
        this.vidalUri = vidalId;
        return this;
    }

    public setUnit(unit: EDosageEquivalent): DrugPrescriptionBuilder {
        this.unitId = this.vidalMapper.mapToVidalUnitId(unit);
        return this;
    }

    public setDuration(duration: number): DrugPrescriptionBuilder {
        this.duration = duration;
        return this;
    }

    public setDose(dose: number): DrugPrescriptionBuilder {
        this.dose = dose;
        return this;
    }

    public setDurationType(type: EDurationUnit): DrugPrescriptionBuilder {
        this.durationType = this.vidalMapper.mapToVidalDurationType(type);
        return this;
    }

    public setFrequencyType(type: string): DrugPrescriptionBuilder {
        this.frequencyType = this.vidalMapper.mapToVidalFrequencyType(type);
        return this;
    }

    public setDosages(dosages: Dosage[]): DrugPrescriptionBuilder {
        this.dosages = dosages;
        return this;
    }

    public addPrescriptionLine(line: DrugPrescriptionLine): DrugPrescriptionBuilder {
        this.prescriptionLines.push(line);
        return this;
    }

    public addDosage(dosage: Dosage): DrugPrescriptionBuilder {
        this.dosages.push(dosage);
        return this;
    }

    public build(): DrugPrescriptionLine[] {
        if (this.vidalUri == undefined) {
            throw VidalPriscaError.INVALID_VIDAL_URI;
        }
        if (this.prescriptionLines.length == 0) {
            return [
                new DrugPrescriptionLine(
                    this.vidalUri,
                    this.unitId,
                    this.dose,
                    this.duration,
                    this.durationType,
                    this.frequencyType
                )
            ];
        } else {
            return this.prescriptionLines;
        }
    }
}
